export default {
  lang: "en",
  login: {
    loginFailed: "Login Failed",
    name: "STEP G-Cloud",
    login: "Login",
    logout: "Logout",
    welcome: "Welcome to ",
    account: "Account",
    password: "Password",
    forgotPassword: "Retrieve password",
    app: "APP",
    qrCodeApp: "Scan to download the elevator cloud APP",
    chromeDown: "Download Now",
    chromeLogin: "Suggest logging in using Chrome browser",
    phone: "Phone",
    verify: "Verify Code",
    newPassword: "New Password",
    submit: "submit",
    sendMSG: "Send",
    resend: "Resend",
    getVer:"Get verification code",
    emptyVer:"Verification code cannot be empty",
    verSuccess:"The SMS was sent successfully and is valid within 10 minutes.",
    verFail:"SMS sending failed",
    verNotRight:"The verification code is incorrect or has expired",
    tips: {
      account: "Please Enter Account",
      password: "Please Enter Password",
      notNull: "Cannot be none",
      logout: "Are you sure you want to log out ?",
    },
  },

  headerBar: {
    app: "APP",
    globalMute: "Global Mute",
    cancelMute: "Unmute",
    mute: "Mute",
    unavailable: "Video surveillance not activated",
    connect: "Connecting...",
    nonsupport: "Current environment not supported",
    connectionFailed: "Connection Failed",
    available: "Available",
    newConnection: "New Connection",
    landed: "Other places landed",
    setting: "Account Setting",
    logout: "Logout",
    uiSetting: "UI Setting",
    board: "Dashboard",
    serial: "Serial port authorization",
    recentMaintWork:
      "The following elevators' maintenance work is about to expire",
    recentContract: "The following contracts is about to expire",
    recentYearCheck:
      "The following elevators' annual inspection will expire soon",
  },
  // 首页
  home: {
    units: "Units",
    lifts: "Lifts",
    onlineLifts: "Online",
    repairLifts: "Repairing",
    parts: "Parts",
    elevatorStatus: "Elevator Status",
    faultType: "Fault Types",
    documents: "Documents",
    events: "Events",
    faults: "Faults",
    enterStopMode: "Stop Mode",
    maintenance: "Maintenance",
    alarms: "Alarms",
    mainPowerLoss: "Power Failure",
    changePassword: "Change password",
    ignore: "Ignore",
    tip: {
      passwordSimple:
        "Password too simple, it is recommended to change it",
    },
    users: "Users",
    liftsLocation: "Elevator distribution",
    info: "Basic Information",
    timeFrame: "Time Frame",
    realTimeInfo: "Real-time Info",
    noFaults: "No faults within the cycle",
  },
  route: {
    bigDataManager: "Data statistics",
    aiManger: "AI monitoring",
    vmVideo: "Intelligent camera",
    checkTemplates: "Exclusion Template",
    weekCheck: "Weekly inspection",
    dayWeekMonthManger: "Daily Weekly and Monthly Supervision",
    dayControl: "Daily Control",
    monthDispatch: "Monthly scheduling",
    useUnitCom: "Use Unit",
    agent: "Agent",
    createCompany: "Manufacturer Company",
    maintCompany: "Maintenance Company",
    propertyCompany: "Property Management Company",
    installCompany: "Installation Company",
    changeCompany: "Modification Company",
    camera: "Intelligent connected camera",
    maintPlan: "Maintenance Plan",
    hint: "Hint",
    monitorDevice: "Camera management",
    home: "Home Page",
    account: "Account management",
    notice: "Announcement information",
    uiSetting: "Interface Settings",
    noticeDetail: "Announcement details",
    elevator: "Elevator",
    peopleCounting: "Headcount statistics",
    elevatorInfo: "Elevator Info",
    device: "Device Status",
    danger: "Danger",
    elevatorUser: "Elevator User",
    monitor: "Monitor",
    useUnitMonitor: "Group Monitor",
    map: "GIS Map",
    singleMonitor: "Single Monitor",
    videoMonitor: "Video monitoring",
    videoCall: "Video Call",
    monitorCenter: "Monitor Center",
    emergencyRepair: "Elevator emergency repair",
    repairJob: "Repair Order",
    annualInspection: "Elevator annual inspection",
    annualInspectionRecord: "Annual Inspection",
    userRole: "User Role",
    user: "User Management",
    role: "Role Management",
    baseInfo: "Base Info",
    useUnit: "Use Unit",
    gatewayVersion: "Gateway program version",
    thirdPartyPlatform: "Third-party platform",
    messagePush: "Message Push Records",
    parts: "Parts Management",
    partsType: "Accessories Type",
    company: "Company management",
    qualitySupervision: "Quality Supervision",
    videoTerminal: "Video Terminal",
    videoUser: "Video User",
    dtuTest: "Gateway test",
    floorDisplay: "Floor Display",
    faultTemplate: "Fault Template",
    developerPermission: "Developer",
    userDebugInfo: "Debugging personnel",
    potentialFaultsetting: "Safety hazard settings",
    deviceChangeRecord: "Old Event Records",
    mb: "Event Records",
    mbPowerLoss: "Main Power Loss",
    faultRecords: "Fault Records",
    integration: "Docking setup",
    alarmRecords: "Alarm Records",
    elevatorMaintenance: "Elevator maintenance",
    maintenanceWorkOrder: "Maintenance Order",
    maintenanceSetting: "Maintenance Setting",
    upkeep: "Upkeep",
    accessPlatform: "Access Platform",
    oldMaintenanceWorkOrder: "Old Maintenance Order",
    msgSend: "SMS sending records",
    userTakeRecord: "Elevator call records",
    msgTemplate: "SMS configuration",
    system: "System Management",
    event: "Event",
    ztVersion: "Private platform",
    equip: "Gateway Devices",
    shanghai: "shanghai Docking",
    wuxi: "wuxi Docking",
    chongqing: "ChongQing Docking",
    shenyang: "shenyang Docking",
    elevatorNetApply: "Net Access Apply",
    homeUser: "Home lift user",
    newMsgSend: "Message Records",
    deviceGateway: "Device Gateway",
    Operations: "Operations Management",
    kanban: "Dashboard display",
    userLoginLog: "Login Log",
    userOperateLog: "Operate Log",
    elevatorSetting: "Elevator Setting",
    potentialFaultSetting: "Safety hazard settings",
    systemSetting: "System Setting",
    contract: "Contract",
    smartScreen: "Smart Screen",
    tpLinkVideo: "TPLink Video",
    eocdPotential: "Eocd Potential",
    tenant: "Tenant Management",
    safeCheckOrder: "Spot check work orders",
    modSalesOrder: "Modification order",
    sysItem: "System parameters",
    video: "Video information",
    banner: "Rotating information",
    product: "Product information",
    fileManage: "Scanning document management",
    vxeTableTest: "VxeTable test",
  },
  tenant: {
    elevatorId: "Elevator ID",
    dataBelong: "Data Belong",
    tenantManage: "Tenant Manage",
    unitInformation: "Unit Information",
    user: "User",
    tplinkCamera: "tplink Camera",
    smartScreen: "Smart Screen",
    belongUnit: "Belong Unit",
    belongTenant: "Belong Tenant",
    tenant: "Tenant",
    loginFailed: "Login Failed",
    settingsSelected: "No setting item selected",
    tenantNotNull: "Tenant cannot be empty",
    tenantStatus:"Tenant Status",
    tenantName:"Tenant Name",
    company:"Initial Company",
    freeze:"freeze",
    createTime:"Creation time",
    updateTime:"Update time",

  },
  camera: {
    upgradeSuccess: "Upgrade Send Success",
    upgrade: "Device Upgrade",
    upgradeFile: "Upgrade File",
    deviceCode: "Device Code",
    screenshot: "Screen Shot",
    overlayOSD: "Overlay OSD",
    pictureQuality: "Picture Quality Level",
    videoWidth: "Video Width",
    videoHeight: "Video Height",
    deviceUpgrade: "Device Upgrade",
    deviceRestart: "Device Restart",
    setTime: "Time setting",
    doorParameterSetting: "Door Parameter Setting",
    basicParameterSetting: "Basic Parameter Setting",
    motorPetalParam: "Motor Petal Param",
    signalValue: "Signal Value",
    lastUpdateStatus: "Last Update Status",
    notUpgraded: "Not Upgraded",
    upgrading: "Upgrading",
    success: "Success",
    fail: "Failed",
    restart: "Restart",
    lastUpdateTime: "Last Update Time",
    lastLoginTime: "Last Login Time",
    lastKeepaliveTime: "Last Keepalive Time",
    snapshotRecord: "Snapshot Record",
    deviceIsOffline: "The device status is offline !",
    makeSureDevice: "Make sure to number the device code",
    restartSuccessfully: "Restart Successfully",
    lastOnlineTime: "Last Online time",
    commonParamSetting: "Common Param Setting",
    serverIp: "Server Ip",
    serverPort: "Server Port",
    doorDetectionEnable: "Door Detection Enable",
    longestOpenTime: "Longest OpenTime",
    doorOpeningTimes: "Door Opening Times",
    alarmDelay: "Alarm Delay",
    visitorFloor: "Visitor Floor",
    check: "Check",
    noCheck: "No Check",
    imageRecord: "Image Record",
    snapshotType: "Snapshot Type",
    snapshotTime: "Snapshot Time",
    manualCapture: "Manual Capture",
    doorRepeatedly: "Door Repeatedly",
    blockDoorLongTime: "Block Door Long Time",
    motorEnterLadder: "Motor Enter Ladder",
    petEnterElevator: "Pet Enter Elevator",
    blockingDoor: "Blocking Door",
    picture: "Picture",
    elevatorDoorEnable: "Elevator Door Enable",
    audibleAlarmEnable: "Audible Alarm Enable",
    lightAlarmEnabled: "Light Alarm Enabled",
    enabledModels: "Enabled Models",
    ladderMaximumTime: "Ladder Maximum Time",
    elevatorEnableTimePeriod: "Elevator Enable Time Period",
    enableFloor: "Enable Floor",
    efficient: "Efficient",
    motorCarCheck: "Motor Car Check",
    bicycleCheck: "Bicycle Check",
    doubleDetection: "Double Detection",
    time: "Time",
    selectDateTime: "select date time",
    supportExtension: "support extension",
    clickToUpload: "Click To Upload",
  },
  user: {
    station: "Please Enter",
    serviceStation: "Service Station",
    childCom: "Please Enter",
    area: "Please Enter",
    unlockSuccess: "Unlock Success",
    unlock: "Unlock",
    lockedAccount: "Locked Account",
    accountUnlock: "Locked Account Unlock",
    correspondRole: "Correspond Role",
    assignAuthority: "Assign Authority",
    tenant: "Tenant",
    user: "User",
    labelWidth: "290px",
    userNameRule:
      "Username is 4~25 characters, can only contain English letters, numbers, @, dots, underscores",
    passwordRule:
      "The password must be 8 to 30 characters and must contain uppercase letters, lowercase letters, and numbers.",
    nameRule: "Name cannot be empty",
    phoneRule: "PhoneNumber cannot be empty",
    validNumber: "please enter a valid phone number",
    userTypeRule: "user type cannot be empty",
    maintWorkerRule: "maintWorker cannot be empty",
    correspondRoleRule: "correspond role cannot be empty",
    username: "Username",
    password: "Password",
    messageSendType: "Notification (CAPTCHA) Sending Method",
    type: "Type",
    name: "Name",
    phone: "Phone",
    repair: "Emergency Repair SMS",
    maintenance: "Maintenance SMS",
    alarm: "Alarm SMS",
    company: "Company",
    lastLoginTime: "Last Login Time",
    quality: "Quality",
    mail: "Mail",
    maintainer: "Maintainer",
    messageControl: "SMS Control",
    changePwdState: "Changeable Password",
    resetPassword: "Reset Password",
    unselectedRole: "Unselected Role",
    selectedRole: "Selected Role",
    afterSale: "After Sale",
    clientUser: "C-suite user",
    cegion: "Cegion",
    branch: "Branch",
    reset: "Reset",
    is: "",
    isMaintWorker: "Maintenance Worker",
    roleIds: "Correspond Role",
    userInfo: "User Info",
    types: {
      0: "Star User",
      1: "Company Administrator",
      2: "Company General Users",
      3: "QA Administrator",
      4: "QA General Users",
      9: "General Users",
    },
    tip: {
      mail: "Please enter the correct mail",
      phone: "Please enter the correct phone",
      password:
        "Password must contain both letters and numbers and 8-30 length",
      tips: "The password is a123456b",
      successful: "Reset password successful",
      failed: "Password reset failed",
    },
    account: {
      baseInfo: "Base Info",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirm: "Confirm",
      tip: {
        confirm: "Two input password inconsistencies",
      },
    },
  },
  homeUser: {
    homeUser: "HomeUser",
  },
  // 合同管理
  contract: {
    packagingForm: "Packaging Form",
    contractSource: "Contract Source",
    expiredStatus: "Expired Status",
    contractStartDate: "Contract Start Date",
    contractEndDate: "Contract End Date",
    copy: "Copy",
    batchDelete: "Batch Delete",
    importContract: "Import Contract",
    appendix: "Appendix",
    uploadTypeLimit:
      "Only files with suffixes jpg, png, bmp, jpeg, xlsx, xls, docx, doc can be uploaded",
    uploadSuccess: "Upload Succeeded",
    uploadFailure: "Upload Failed",
    uploadSizeLimit: "The upload file size cannot exceed 10M",
    download: "Download",
    paymentMethod: "Payment Method",
    elevatorSelect: "Elevator Selected ",
    tower: " Tower",
    fileInfo: "Appendix Info",
    addFile: "Add Appendix",
    fileName: "Appendix Name",
    uploadTime: "Upload Time",
    uploader: "Uploader",
    labelWidth: "160px",
    contractInfo: "Contract Info",
    check: "Check",
    upload: "Upload",
    delete: "Delete",
    contractNo: "Contract No",
    siteName: "Site Name",
    remindExpired: "Remind Expired",
    beExpired: "Be Expired",
    expiresMonth: "Expires in one month",
    expiresThreeMonths: "Expires in three months",
    contractPrice: "Contract Price(/RMB)",
    selectOneContract: "Please select at least one contract",
    voidedContract:
      "Are you sure to void the selected contract, the voided contract cannot be recovered!",
    firstSelectContract: "Please select a contract first",
    mostOneContract: "Select at most one contract",
    inspectionUndertaker: "Inspection Undertaker",
    speedLimiterUndertaker: "SpeedLimiter Undertaker",
    brakeTestFeeUndertaker: "Brake Test Fee Undertaker",
    freeAccessoriesQuota: "Free Accessories Quota(/RMB)",
    monthlyUnitPrice: "MonthlyUnit Price(/RMB)",
    agencyFees: "Agency Fees(/RMB)",
    contactName: "Contact",
    contactInformation: "Telephone",
    isSubcontract: "Whether Subcontract",
  },
  part: {
    addChild: "Adding Sublevels",
    exportAuthenticated: "Export authenticated",
    export: "Export list",
  },
  ota: {
    operateTime: "Operate Time",
    balance: "Balance",
    apply: "Apply",
    burn: "Burn",
    type: "Type",
    operateRecord: "Operate Record",
    burningTimes: "Burning Times",
    name: "Name",
    account: "Account",
    closure: "Closure",
    pass: "Pass",
    reject: "Reject",
    rejectPeople: "Reject People",
    rejectReason: "Reject Reason",
    rejectTime: "Reject Time",
    applyProgram: "Apply Program",
    applicant: "Applicant",
    applicantAccount: "Applicant Account",
    applicantName: "Applicant Name",
    applicationTime: "Application Time",
    approver: "Approver",
    approverTime: "Approver Time",
    approverAccount: "Approver Account",
    approverName: "Approver Name",
    details: "Details",
    programList: "Program List",
    taskList: "Task List",
    applyList: "ApplyL ist",
    user: "User",
    isEffective: "isEffective",
    locking: "Locking",
    effective: "Effective",
    program: "Program",
    count: "Count",
    updateTime: "UpdateTime",
    ownedCompany: "Owned Company",
    applyNumber: "Apply Number",
    remainingNumber: "Remaining Number",
    note: "Note",
    numberNotNull: "Apply Number Can not be empty",
    applyNumberMore:
      "The number of applications cannot be greater than the remaining times",
    approvalStatus: "Approval Status",
    applying: "Applying",
    approved: "Approved",
    rejected: "Rejected",
    endTime: "End Time",
    applyDetails: "Apply Details",
    currentNumber: "Currently burnable number of times",
  },
  // 电梯档案
  elevator: {
    eocdException: "EOCD Exception",
    code: "Code",
    agent: "Agent",
    report: "Statistics",
    esdt: "ESDT",
    esdtFault: "ESDT fault record",
    emtFault:"EMT fault record",
    nvrFault:"NVR fault record",
    emt: "EMT",
    elevatorDetails: "Elevator Details",
    baseInfo: "BaseInfo",
    iot: "IoT",
    elevatorParts: {
      id: "ID",
      parts: "Parts",
      elevatorID: "Elevator Id",
      partsID: "Parts Id",
      elevatorParts: "Elevator Parts",
      add: "add",
      category: "category",
      number: "number",
      name: "name",
      serialNumber: "serial Number",
      floor: "Floor",
      status: "Authentication Status",
      activated: "Certified",
      notActivated: "Uncertified",
      remove: "Removed/Unbundled",
      activateTime: "Activate Time",
      activator: "Activator",
      createTime: "Create Time",
      replace: "replace",
      replacementRecord: "Replacement Record",
      oldParts: "old Parts",
      newParts: "new Parts",
      replacementTime: "replacement Time",
    },
    record: "Record",
    longAntLat: "Device's Longitude and Latitude",
    unknown: "Unknown",
    selectReporting: "Please select a reporting period",
    selectOne: "Please select at least one",
    unselectedElevator: "Unselected Elevator",
    selectedElevator: "Selected Elevator",
    checkMultiReport: "Check Multi Elevator Report",
    saveReport: "Whether to save as a multi-ladder report for quick viewing?",
    reportName: "Report Name",
    elevatorCount: "Elevator Count",
    templateNameNotNull: "Template Name Not Null",
    createReport: "Create Report",
    reportStateCycle: "Report State Cycle",
    singleElevator: "Single Elevator Report",
    multiElevator: "Multi Elevator Report",
    monitorTime: "Monitor Continuous Time(minutes)",
    registerCode1: "Register Code",
    batchModification: "Batch Modification",
    faultTemplate: "Fault Template",
    floorDisplayTemplate: "Floor Display",
    salesman: "Salesman",
    salesmanTel: "salesmanTel",
    ota: "OTA",
    projectName: "Project Name",
    authorizedFloor: "Authorized Floor",
    authorizedStart: "Authorized Start Time",
    authorizedEnd: "Authorized End Time",
    count: "counts",
    authorizationTime: "Authorization Time",
    callElevator: "Call Elevator",
    autoGenerate: "Auto generate daily, weekly, and monthly reports",
    checkTemplates: "Check Templates",
    belongCompany: "Belong Company",
    elevatorsOnline: "The following elevators are offline or online",
    isHomeUse: "Is Home Use",
    follow: "Follow",
    ownerPhoneNo: "Owner Phone Number",
    monitorElevators: "Monitor up to eight elevators at a time",
    selectElevator: "Please select the elevator first",
    selectFloorDisplay: "Please select the floor display first",
    selectFaultTemplate: "Please select the fault template first",
    parameterEmpty: "The parameter cannot be empty",
    selectMaintenance: "Please select a maintenance enterprise",
    selectPropertyCom: "Please select a Property enterprise",
    maintenanceWorker: "Please select at least one maintenance worker",
    sameMaintenanceWorker: "Can not choose the same maintenance worker!",
    samePropertySafetyOfficer: "Can not choose the same safety officer!",
    samePropertySafetyDirector: "Can not choose the same safety director!",
    floorComparisonTable: "Floor Comparison",
    alarmDelayTime: "Alarm Delay Time",
    maintenanceMan: "Maintenance Man",
    dockingPlatform: "Docking Platform",
    exportExcel: "Export Excel",
    labelWidth: "220px",
    labelWidthList: "150px",
    labelWidthAlarm: "100px",
    stopStatus: "Current Floor Stop Status",
    yes: "Yes",
    not: "No",
    confirmChoice: "Confirm Choice",
    timeOfOccurrence: "Time of Occurrence",
    gatewayPowerDown: "Gateway Power Down",
    nonGateAreaStop: "Non Gate Area Stop",
    repair: "Repair",
    powerDownTime: "Power Down Time",
    whetherToRestore: "Whether to Restore",
    alarmBell: "Alarm Bell",
    enterOutOfService: "Enter Out of Service",
    powerFailure: "Power Failure of Main Power Supply",
    floorQr: "Floor QR code Download",
    elevator: "Elevator",
    addElevator: "Add the elevator",
    no: "Elevator No.",
    address: "Address",
    person: "Person",
    elevatorType: "Elevator Type",
    registerCode: "Device Code",
    name: "Elevator Name",
    brand: "Brand",
    model: "Model",
    maintWorker: "Maintenance worker",
    maintType: "Maintenance Type",
    safetyOfficer: "Safety Officer",
    safetyDirector: "Safety Director",
    safetyMainer: "Safety Mainer",
    buildingNo: "Building No.",
    elevatorNo: "Elevator No.",
    dtuCode: "DTU Number",
    nodeCode: "Node Code",
    monitorType: "Monitor Type",
    monitorType1: "Protocol",
    monitorType2: "Peripheral Sensor (bufan)",
    monitorType3: "Peripheral Sensor (zhongyi)",
    gateWayType: "GateWay",
    gateWayType1: "ESDT",
    gateWayType2: "TPL-NVR",
    gateWayType3: "EMT",
    dtuModel: "DTU Model",
    simCardNo: "SIM Card No.",
    faultCode: "Fault Code",
    iccid: "ICCID",
    exFactoryNo: "Ex-Factory No.",
    controlCabinetLeaveNo: "Control Cabinet Leave No.",
    exFactoryNoWx: "Ex-Factory No WX",
    outerDeviceWx: "OuterDevice WX",
    statusWx: "Status WX",
    maintPeriod: "Maintenance Period(days)",
    whereUsed: "Where Used",
    videoEquip: "Video Equip",
    deviceGateway: "Device Gateway",
    notInstalled: "Not Installed",
    installed_plug: "Installed(plug)",
    installed_link: "Installed(link)",
    videoChannelId: "Car Video Channel Id",
    videoUrl: "Car Video Url",
    motorRoomVideoUrl: "Car Video Url FLV",
    video: "Video",
    installed: "Installed",
    joinStatus: "Join Status",
    isParallel: "Is Parallel",
    openStatus: "Open Status",
    open: "open",
    notOpen: "Not Open",
    halfOpen: "Half Open",
    onlineStatus: "Online Status",
    activatedPartsStatus: "Activated Parts Status",
    accessStatus: "Access Status",
    accessPlatform: "Platform access",
    activated: "Activated",
    notActivated: "Not Activated",
    all: "All",
    accessed: "Accessed",
    notAccessed: "Not Accessed",
    useStatus: "Use Status",
    useUnit: "Use Unit",
    property: "Property",
    maintenanced: "Maintenanced",
    propertyCom: "Property",
    qualityOrgan: "Quality Organ",
    manufactured: "Manufacturing Company",
    installationEnterprise: "Installation Enterprise",
    reconstructed: "Reconstructed",
    locCode: "Location code",
    exFactoryDate: "Ex-Factory Date",
    installationDate: "Installation Date",
    joinDate: "Join Date",
    simExpireDate: "SIM Expire Date",
    signalType: "Signal Type",
    serviceDate: "Service Date",
    test: "Test",
    check: "Check",
    floors: "Floors",
    ratedSpeedOfEscalator: "Rated Speed of Escalator(m/s)",
    riseOfEscalator: "Rise of Escalator(m)",
    angleOfEscalator: "Angle of Escalator(degree)",
    widthOfEscalator: "Width of Escalator(m)",
    ratedSpeedOfMovingWalk: "Rated Speed of Moving Walk(m/s)",
    lengthOfMovingWalk: "length of Moving Walk(m)",
    angleOfMovingWalk: "Angle of Moving Walk(degree)",
    widthOfMovingWalk: "Width of Moving Walk(m)",
    highestFloorOfElevator: "highest Floor of Elevator(floor)",
    ratedSpeedOfElevator: "Rated Speed of Elevator(m/s)",
    ratedSpeedOfElevatorRule: "Rated Speed of Elevator",
    ratedLoadOfElevator: "Rated Load of Elevator(kg)",
    locationInfo: "Location Data",
    actualFloor: "Actual Floor",
    showFloor: "Show Floor",
    displayedFloor: "Displayed Floor",
    currentFloorCode: "Current Floor Code(A0)",
    faultFloorCode: "Fault Floor Code(MC)",
    faultFloor: "Fault Floor",
    addFloorDisPlay: "Add floor disPlay",
    peopleStuckAlarmDelayTime: "People Stuck Alarm Delay Time(s)",
    notAvailableAlarmDelayTime: "Not Available Alarm Delay Time(s)",
    finishDelayTime: "Finish Delay Time(s)",
    longitude: "Longitude",
    latitude: "Latitude",
    joined: "Joined",
    notJoined: "Not Joined",
    useStatus_1: "Using",
    useStatus_2: "Dumping",
    useStatus_3: "Unused",
    useStatus_4: "Cancellation",
    maintenanceBySelf: "Maintenance by Self",
    maintenanceByOthers: "Maintenance by Others",
    production: "Production",
    using: "Using",
    maintenance: "Maintenance",
    system: "system",
    fault: "fault",
    enterprise: "Enterprise",
    parameters: "Parameters",
    extensionParameters: "Extension Parameters",
    modifyLocation: "Modify Location",
    add: "Add",
    generalParameters: "General Parameters",
    floorDisplay: "Floor Display",
    alarmParameters: "Alarm Parameters",
    location: "Elevator Location",
    emergencyRecord: "EmergencyRecord",
    maintenanceRecord: "Maintenance Record",
    yearCheckRecord: "Year Check Record",
    faultRecord: "Fault Record",
    deviceEvent: "Event record",
    selfProtectionRecord: "Self-protection Record",
    otherRecord: "Other Record",
    potentialFault: "Potential Fault",
    device: "Device",
    runTimes: "run Times",
    gradeAlarming: "Grade Alarming",
    maintComSelectTip: "Please choose the maintenanced first",
    propertyComSelectTip: "Please choose the property first",
    elevatorDebuggingPlatform: "Elevator debugging platform",
    elevatorCheck: "Elevator Check",
    lock: "lock the elevator",
    unlock: "unlock the elevator",
    Lock: "Lock",
    Unlock: "Unlock",
    closeAuth: "Close Auth",
    openAuth: "Open Auth",
    activationBinding: "Activation binding",
    AllBoards: "All the boards in the system",
    elseBoards: "else(ex-board etc.)",
    simCardBinding: "Sim card binding",
    groupControlBoard: "Group control board",
    frequencyConverter: "Frequency converter",
    carBoard: "Car board",
    sedanRoof: "Sedan roof",
    callBoard: "Call board",
    changeProbationPeriod: "Change the probation period",
    escalatorLock: "Escalator Lock",
    escalatorUp: "Escalator Up",
    escalatorDown: "Escalator Down",
    escalatorRun:"Escalator Running",
    escalatorStop:"Escalator Stop",
    energryRun:"Energy-Saving Operation​",
    fire:"fire",
    breakSystem:"Additional Brake System",
    UnintendedReversal:"Unintended Reversal",
    missingStep:"Missing Step or Pallet",
    handSpeed:"Handrail Speed Deviation",
    breakFail:"Service Brake Failure",
    otherFault:"Other Faults Preventing Restart of Escalators and Moving Walks",
    datSta:"Data statistics",
    slow:"Slow",
    fast:"Fast",
    stop:"Stop",
    day: "day",
    hour: "hour",
    openDebugAuth: "Open debug Auth",
    grade: "Grade",
    time: "Time",
    stopOrStart: "Stop or Start",
    lockOrUnlock: "Lock or Unlock contract No.",
    contractNo: "Contract No.",
    refresh: "refresh",
    operationRecord: "Operation record",
    type: "type",
    order: "order",
    status: "status",
    operator: "operator",
    executing: "executing",
    success: "success",
    error: "error",
    overTime: "overTime",
    copy: "Copy",
    batchAdding: "Batch adding elevator",
    batchCopy: "Batch copy this elevator",
    addNumber: "Add number",
    importSimInfo: "Import Sim Info",
    downloadSimTemplate: "Download Sim Info Import Template",
    uploadType: "Please upload. XLSX file type",
    importSuccess: "Import Success",
    importFailure: "Import Failure",
    faultNum:"Fault number",
    faultProject:"Faulty items",
    faultName:"Fault name",
    isAnyone:"Is anyone trapped",
    tip: {
      maintPeriod: "maintPeriod must be more than 0",
      operateSuccess: "operate Success",
      operateError: "operate Error",
      atLeastOne: "Please choose at least one item",
      daysAndHours: "Please fill in the days and hours correctly",
      gradeAndTime: "Please fill in grade and time correctly",
      chooseFirst: "Please choose first",
      want: "Do you want to ",
      contractNo: "The contract NO can not be empty",
      batchAddition: "Batch addition does not need to be filled in",
      needRegNO: "Can't be empty, please fill the inEx-Factory No.",
    },
  },
  deviceGateway: {
    turnOn: "Turn on",
    closed: "Close",
    edit:"Edit",
    isSendAlarmSms: "Is Send Alarm Sms",
    isInsertJxOrder: "Is Insert JxOrder",
    MakeSureProtocols: "Make sure to set up supported protocols?",
    dtuNotNull: "DTU Code cannot be empty",
    lastTime1: "Last Time",
    pushCount: "Push Count",
    dtuNode: "DTU Code:Node",
    processing:
      "There are too many pushes, and the gateway program may be abnormal. Please contact relevant personnel for corresponding processing.",
    GatewayPushStatistics: "Gateway push statistics",
    labelWidth1: "150px",
    refresh: "Refresh",
    sendA0: "Send A0",
    type: "Type",
    packet: "Packet",
    receivingTime: "Receiving Time",
    repair: "Shenyang-A0 status data repair",
    runNumber: "Number of runs",
    runTime: "Running time",
    setting: "Setting",
    read: "Read",
    clear: "Clear",
    lastTime: "A0 compares the current number of runs with the last time",
    dockingOffline:
      "Shenyang docking is offline and powered off for 60 seconds",
    OfflinePower: "Offline->Power off",
    A0Running: "A0 running start and stop time",
    initialRunNumber: "Number of initial runs",
    initialRunTime: "Initial run time",
    currentRunNumber: "Current number of runs",
    currentRunningTime: "Current running time",
    polarP: "polar logarithm p",
    motorType: "Motor type",
    synchronous: "Synchronous",
    asynchronous: "Asynchronous",
    MkEvent: "Simulated elevator event (MK new event)",
    automatic: "Automatic",
    overhaul: "Overhaul",
    normal: "Normal",
    AlarmBell: "Alarm bell",
    PowerGateway: "Power on the gateway",
    GatewayPowered: "Gateway Powered",
    orderMkEvent: "Simulated work order (MK new event)",
    NotTrappingPeople: "Not trapping people",
    TrappingPeople: "Trapping People",
    elevatorBind: "Elevator Bind",
    groupControl: "Is Parallel group control",
    normallyOpen: "Normally Open",
    normallyClose: "Normally Close",
    updateOnlineStatus: "Update online status",
    updateBindingStatus: "Update binding status",
    gatewayRouteSet: "Set Gateway Route",
    pushStatistics: "Push statistics",
    sendOrder: "Send Order",
    faultCode: "Fault Code",
    monitor: "monitor",
    video: "Video",
    selfStudy: "self-study",
    close: "normal close",
    open: "normal open",
    singleDoor: "SingleDoor",
    doubleDoor: "DoubleDoor",
    smoke: "Single Door + Smoke Detector",
    openDoorInPlace: "Single Door + Open the door in place",
    other: "other",
    liveSubscription: "Live Subscription",
    liveStop: "Live Stop",
    onlineElevator: "电梯在线同步",
    state: "State",
    audio: "Audio",
    uModel: "U-Model",
    wired: "wired",
    networkFormat: "Network Format",
    inNetTime: "InNetTime",
    sensor: "Sensor",
    terminalNo: "Terminal No",
    infraredType: "Infrared type",
    elevatorSpeed: "Elevator Speed",
    carDoorType: "Car Door Type",
    judgmentsNumber: "Judgments Number",
    openDoorTime: "openDoorTime",
    horizontalSpacing: "Horizontal Spacing",
    editBlack: "Edit Blacklist",
    editRoute: "Edit Routing",
    downloadingTemplate: "Download Import Template",
    blacklist: "Blacklist",
    supportProtocol: "Support Protocol",
    bound: "Bound",
    unbound: "Unbound",
    selectDevice: "Select the device gateway for which you want to edit",
    selectBlacklist:
      "Select the device gateway for which you want to set the blacklist",
    selectSupportProtocol:
      "Select the device gateway for which you want to set the protocol",
    labelWidth: "180px",
    editDevice: "Edit Device Gateway",
    ESDT: "ESDT",
    EMT: "EMT",
    NVR: "NVR",
    import: "Import",
    dtuCode: "DTU Code",
    deviceModel: "Device Model",
    signalType: "Signal Type",
    simCode: "SIM Code",
    simICCID: "SIM ICCID",
    signalStrength: "Signal Strength",
    ip: "ip",
    softVer: "Soft Version",
    hardVer: "Hard Version",
    bindState: "Bind State",
    temperature: "Device Temperature",
    devicePower: "Device Power",
    linkStatus: "Motherboard Link Status",
    blackStatus: "Black Status",
    blackYes: "Yes",
    blackNo:"No",
    dtuStatus: "Online Status",
    expirationTime: "SIM Card Expiration Time",
    updateTime: "Last Update Time",
    inNetState: "InNet State",
    communicationFailed: "Communication Failed",
    communicationNormal: "Communication Normal",
    dtuOnline: "Online",
    call: "Calling",
    dtuOffline: "Offline",
    onlineState: "Online State",
    onlineUpdateTime: "Online Update Time",
    invalid: "Invalid",
    accessTime: "Access Time",
    deviceCard:
      "Only one gateway device can be selected to view the traffic card",
    trafficCard:
      "Please select the gateway device that needs to view the traffic card in the list first.",
    selectOne: "Only one gateway device can be selected",
    deviceStatus: "Only one gateway device can be selected to view status",
    checkDevice:
      "Please select the gateway device whose status you want to check in the list first.",
    no: "No",
    yes: "Yes",
  },
  // 入网申请
  elevatorNetApply: {
    eleNotEmpyty:"The network access date of the networked elevator cannot be empty",
    dtuNotEmpyty:"The DTU number of the elevator connected to the network cannot be empty",
    downloadTemplate: "Download Import Template",
    importElevator: "Import Elevator",
    createSource: "Create a Source",
    dtuCode: "DTU Number",
    factoryNumber: "Factory Number",
    elevatorName: "Elevator Name",
    useUnit: "Use Unit",
    manufacturerCompany: "Manufacturer Company",
    maintenanceCompany: "Maintenance Company",
    webAdd: "Web Page Addition",
    webImport: "Web Page Import",
    app: "App",
    apiImport: "Api Import",
    underReview: "Under Review",
    reviewed: "Reviewed",
    uploadType: "Please upload. XLSX file type",
    importSuccess: "Import Success",
    importFailure: "Import Failure",
    importOnly: "You can import only one file at a time",
    floorMappings: "Floor Comparison",
    proName: "Project Name",
    addFloorMappings: "Please Add Floor Information",
    generateSuccess: "Generate success",
    labelWidth: "230px",

    reset: "Reset",
    search: "Search",
    add: "Add",
    useUnitDistrict: "District",
    useUnitAddress: "Address",
    maintComName: "Maintenance Company",
    manufactured: "Manufacture Company",
    createCom: "Manufacturer Company",
    createCreditCode: "Manufacturer Company Credit Code",
    maintCreditCode: "Maintenance Company Credit Code",
    maintEmpName: "Maintenance personnel",
    maintEmpPhone: "Phone",
    propertyCom: "Property Management Company",
    brand: "Brand",
    buildingNo: "Building Number",
    load: "Load",
    dtuModel: "Dtu Model",
    nodeCode: "Node Code",
    regCode: "Device Code",
    sim: "SIM",
    elevatorModel: "Elevator Model",
    controlModel: "Control Model",
    elevatorType: "Elevator Type",
    speed: "Speed",
    ladderNo: "Ladder Number",
    exFactoryDate: "Factory Date",
    installationDate: "Installation Date",
    videoChannelId: "Camera Id",
    companyInfo: "Company Information",
    elevatorInfo: "Elevator Information",
    operate: "Operate",
    generate: "Generate",
    generated: "Generated",
    edit: "Edit",
    delete: "Delete",
    floorLocation: "Floor Location",
    displayCode: "Display Code",
    addElevatorNetApply: "Add ElevatorNetApply",
    editElevatorNetApply: "Edit ElevatorNetApply",
    pleaseEnter: "Please Enter",
    notNull: "Cannot be empty",
    cancel: "cancel",
    save: "save",
    installAddress: "Install Address",
  },

  // 急修工单
  workOrder: {
    workOrderType: "Work Order Type",
    alarmType: "Alarm Type",
    workOrderStatus: "Repair Job Status",
    alarmTime: "Alarm Time",
    humanCauses: "Human Causes",
    externalCauses: "External Causes",
    doorSystem: "Door System",
    tractionSystem: "Traction System",
    guidanceSystem: "Guidance System",
    controlSystem: "Control System",
    electricalSystem: "Electrical System",
    protectionDevice: "Safety Protection Device",
    faultAnalysis: "Fault Analysis",
    liftCar: "LiftCar",
    orderCancel: "Order Cancel",
    orderConfirm: "Order Confirm",
    faultReason: "Fault Reason",
    handleMethod: "Handle Method",
    extensionNumber: "Extension Number:",
    choiceDate: "Please fill in the correct date!",
    choicePrice: "Please fill in the correct price!",
    faultCode: "Fault Code",
    screenshot: "Screenshot",
    elevatorGateways: "The following elevator gateways are powered off",
    elevatorsRepairs: "The following elevators are in urgent repair",
    elevatorAlarm: "The following elevator alarm bell alarm",
    followingOverdue: "The following annual inspections are overdue",
    maintenanceExpire: "The following maintenance is about to expire",
    pleaseSelect: "Please Select Elevator",
    elevator: "Elevator",
    workOrder: "Repair Job",
    labelWidth: "140px",
    information: "Work order information",
    workOrderNo: "Work Order No.",
    alarmType_0: "Not Available",
    alarmType_1: "People Stuck",
    alarmType_2: "Alarm Bell",
    alarmType_3: "Serious",
    deviceReasonTrapped: "Device Reason Trapped",
    deviceReasonSafe: "Device Reason Safe Problem",
    elevatorDamageOrFault: "Elevator Damage Or Fault",
    minorIssues: "Minor Issues",
    userReasonNoTrapped: "User Reason No Trapped",
    userReasonTrapped: "User Reason Trapped",
    manualAlarm: "Manual Alarm",
    automaticAlarm: "Auto Alarm",
    callRecord: "Call Record",
    faultDesc: "Describe the fault situation during the call",

    deviceCode: "Device Code",
    acceptor: "Acceptor",
    repairPeople: "Repair People",
    repairMethod: "Repair Method",
    selfRepair: "Self Repair",
    appRepair: "App Repair",
    phoneCall: "Phone Call",
    inspection: "Year Inspection",
    contactPerson: "UseUnit Person",
    contactTel: "Phone",
    servicePersonnel: "Service Personnel",
    hasPeople: "Has People",
    noPeople: "No People",
    recordState_0: "Alarm Reported",
    recordState_1: "Alarm Accepted",
    recordState_2: "Arrived",
    recordState_3: "Completed",
    recordState_4: "Recovery",
    recordState_5: "Two Alarm Completed",
    recordState_6: "Confirmed",
    recordState_7: "Auto Confirmed",
    recordState_8: "Cancelled",
    alarmReported: "Alarm Reported",
    notReported: "Not Reported",
    alarmed: "Alarmed",
    unAlarmed: "Not Alarmed",
    arrived: "Arrived",
    notArrived: "Not Arrived",
    finished_: "Finished",
    recovery: "Recovered",
    notFinished: "Not Finished",
    twoAlarmCompleted: "Two Alarm Completed",
    confirmed_: "Confirmed",
    notConfirmed: "Not Confirmed",
    autoConfirmed: "Auto Confirmed",
    cancelled: "Cancelled",
    received: "Received",
    finished: "Finished",
    completed: "Completed",
    confirmed: "Confirmed",
    canceled: "Canceled",
    receive: "Receive",
    finish: "Finish",
    complete: "Complete",
    confirm: "Confirm",
    cancel: "Cancel",
    baseInfo: "BaseInfo",
    alarmReportedBy: "Alarm People",
    alarmPeopleTel: "Alarm People Phone",
    emergencyRepairRecord: "Emergency Repair Record",
    system: "System",
    faultRecords: "Fault Records",
    faultNo: "Fault No.",
    faultType: "Fault Type",
    faultTime: "Fault Time",
    callTime: "Call Time",
    mainBoardTime: "Main Board Time",
    faultStorey: "Fault Storey",
    faultFloorCode: "Fault Floor Code",
    faultFloor: "Fault Floor",
    displayFaultFloor: "Display Fault Floor",
    unKnowFault: "UnKnow Fault",
    elevatorInfo: "Elevator Info",
    monitorElevator: "Monitor elevator",
    propertyInfo: "Property Info",
    revocationReason: "Revocation Reason",
    confirmPeople: "Confirm People",
    propertyName: "Property Name",
    hex: "Hex",
    workorderInfoTrack: "Workorder Info Track",
    deviceInfo: "Device Info",
    status: "Status",
    onLine: "On Line",
    offLine: "Off Line",
    createTime: "Time",
    launcher: "Launcher",
    rescueMap: "Rescue Map",
    currentLocation: "Current Location >Elevator Emergency Maintenance",
    tip: {
      stoped: "The process has been suspended and cannot be operated.",
      cantRepeat: "Cannot repeat the Service Personnel",
      want: "Do you want to ",
      mark: "?",
    },
  },
  floorDisplay: {
    floorDisplay: "Floor Display",
    floorDisplayNo: "Floor Display No.",
    no: "No.",
    name: "Name",
    setting: "Setting",
  },
  faultTemplate: {
    subCode: "SubCode",
    startFloor: "Start Floor",
    endFloor: "End Floor",
    templateNo: "Template No.",
    isBuiltIn: "Is Built-in",
    editDefaultFaultTemplate: "Edit Chinese Default Fault Template",
    editDefaultFaultTemplateEn: "Edit English Default Fault Template",
    faultTemplate: "Fault Template",
    faultTemplateNo: "Fault Template No.",
    companyName: "Company Name",
    no: "Number",
    name: "Name",
    mark: "Mark",
    type: "Type",
    type1: "Control System",
    type2: "Frequency Converter",
    type3: "Door Machine",
    baseInfo: "BaseInfo",
    faultCode: "Fault Code",
    faultType: "Fault Type",
    SerialNo: "No.",
    solution: "Solution",
    maintReco: "MaintReco",
    reason: "Reason",
    addaRow: "Add a Row",
    deleteSelectedRow: "Delete Selected Row",
    generatingGeneralFaultCode: "Generating general fault code",
    duplicateFaultCode: "There are duplicate fault codes",
    remark: "Remark",
  },
  developer: {
    developer: "developer",
    no: "Code",
    name: "Name",
    appKey: "AppKey",
    appSecret: "AppSecret",
    status: "Status",
    contact: "Contact",
    mobile: "Mobile",
    address: "Address",
    auth: "Auth",
    activate: "Activate",
    close: "Close",
    callbackUrl: "Call Back Url",
    type: "Type",
    baseAlarmUrl: "Base Alarm Url",
    baseAlarmStatusUrl: "Base Alarm Status Url",
    baseFaultUrl: "Base Fault Url",
    baseEventUrl: "Base Event Url",
    baseTerminalUrl: "Base Terminal Url",
    information: "Basic Information",
    Subscription: "Subscription Service",
    subscriptionServiceList: {
      alarm: "Alarm",
      remove: "Remove Alarm",
      repeal: "Repeal Alarm",
      affair: "Affair",
      fault: "Fault",
    },
    tip: {
      no: "Code cannot be empty",
      name: "Name cannot be empty",
      mobile: "Please enter the correct phone number",
      appSecret: "The length is 8 digits and the letter combination",
      activateSucceed: "Activation successful",
      closeSucceed: "Close the success",
    },
  },
  developerDataAuthority: {
    developerDataAuthority: "Data Permission",
    useUnitNo: "UseUnit No.",
    useUnitName: "Name",
  },
  dtuTest: {
    tips: "Tips",
    code: "Code",
    add: "Add DTU",
    ps: "Notes",
    frequency: "Frequency/Time",
    startTesting: "Start testing",
    countdown: "Countdown",
    second: "Second",
    retest: "Retest",
    successCount: "Number of successes",
    failedCount: "Number of failures",
    tip: {
      message:
        "Each test lasts 5 minutes. Please wait patiently" +
        "test will be interrupted, you should restart.",
      tip1: "Please fill in the Dtu code",
      tip2: "The Dtu cannot be found. Searching again",
      tip3: "Test time can not be more than 24 hours",
      tip4: "Pass times keey null, or fill in the positive integer",
      tip5: "You cannot add multiple DTU at the same time.",
      tip6: "You can only add up to 50 DTU,Please delete before adding",
      tip7: "It has to be 11 digits，such as “20130113092”",
      tip8: "Are you sure about leaving the DTU test ?",
    },
  },
  // 健康指数
  potentialFault: {
    healthIndex: "Health Index",
    healthIndex_low: "Low",
    healthIndex_middle: "Medium",
    healthIndex_high: "High",
    potentialFaultName: "Hidden Danger Types",
    totalPoints: "Toatl Points",
    warningValue: "Warning Value",
    faultCode: "Fault Code",
    faultDesc: "Fault Desc",
    weight: "Weight",
    faultCount: "Fault Count",
    faultOccurrenceTime: "Fault Time",
    potentialFault: "Elevator Safety Hazards",
    potentialFaultDetails: "Safety Risk Details",
  },
  // 节目单
  programCard: {
    programCardName: "Program Name",
    notice: "Notice",
    companyName: "Company Name",
    notSupportVideo: "Your browser does not support the Video TAB",
    programCard: "Program",
    edit: "Edit Program",
    add: "Add Program",
    prompt1: "* Supports image (jpg/jpeg, png, gif) and video types (mp4)",
    prompt2:
      "* Hold down the Ctrl key in the selection box and click the mouse to select multiple",
    prompt3:
      "* The size of a single video should not exceed 300M, and the size of a single picture should not exceed 3M.",
    prompt4:
      "* Some mp4 VIDEOS cannot be PREVIEWED IN the browser due to coding problems, but play normally on the screen.",
    upload: "Upload",
    program: "Program",
    sorting: "Sorting",
    imageFiles: "Image files cannot exceed 3MB!",
    videoFiles: "Video files cannot exceed 300MB!",
    fileType: "Please upload the allowed file type",
    uploadSuccessful:
      "The upload is successful. Click Save to make it take effect",
  },
  // 智能摄像
  tpLink: {
    deviceStatus: "Device State",
    deviceName: "Device Name",
    mac: "MAC Address",
    preview: "Preview",
    playBack: "Replay",
    alarm: "Alarm",
    peoples: "Number of People",
    boundElevator: "Bound Elevator",
    deviceType: "Device Type",
    deviceModel: "Device Model",
    synchronizationSucceeded: "Synchronization Succeeded",
    synchronizationFailed: "Synchronization Failed",
    alarmRecord: "Alarm Record",
    synchronization: "Synchronization",
    alarmSetting: "Alarm forwarding setting",
    url: "Server Url",
    videoType: "Video Type",
    shiLian: "SL-IPC-XSD001/E216",
    tpLink: "TPLink",
    cameraReplace:
      "The camera has been bound to another elevator. Replace it or not?",
  },
  // 智能屏幕
  elevatorLcd: {
    elevatorLcd: "Smart Screen",
    programList: "Program List",
    videoUser: "Video User",
    onLineStatus: "Oline Status",
    elevatorNo: "Elevator No.",
    elevatorName: "Elevator Name",
    useUnitName: "Use Unit",
    terminalID: "Terminal Serial Number",
    heartTime: "Heartbeat Time",
    online: "Online",
    offline: "Offline",
    currentProgram: "Current Program List",
    previewProgram: "Preview",
    controlSystem: "Access System",
    elevatorDtuCode: "DTU Number",
    replace:
      "This elevator has been bound to other terminals. Do you want to replace it?",
    tip: {
      lcdId: "MAC Unique",
      mac: "It has to be 17 digits",
      password: "The length is 6-20 digits and the letter combination",
      elevator: "Elevators cannot be empty",
    },
    elevatorRegisterNo: "Register",
    lcdId: "Mac",
    password: "Password",
  },
  msgPushRecord: {
    messagePush: "Message Push",
    allMsgPush: "Push messages to all users.",
    placeholder: "Please enter message content, up to 500 words",
    messageId: "Message",
    msgType: "Message Type",
    content: "Content",
    username: "User",
    createTime: "CreateTime",
    allUser: "All User",
    tip: {
      pushSuccess: "Push message success",
      pushFailure: "Push message failed",
    },
    common: "common",
    alarmBell: "alarm bell",
    workOrder: "repair Job",
    powerLoss: "main power loss",
  },
  publicNotice: {
    more: "More",
    noticeTitle: "Title",
    publishDate: "Release date",
    status: "isShow",
    name: "Editor",
    noticeDesc: "Content",
    tip: {
      Title: "The title cannot be empty",
    },
  },
  parts: {
    labelWidth: "155px",
    bindingRecord: "Binding Record",
    export: "Export List",
    no: "Number",
    name: "Name",
    partsTypeName: "PartsTypeName",
    elevatorCompanyName: "ElevatorCompanyName",
    partsSn: "PartsSn",
    model: "Model",
    brand: "Brand",
    price: "Price",
    times: "Usage count",
    life: "Term",
    status: "Status",
    creatorName: "Creator",
    elevatorProductDescription: "Describe",
    hot: "Is it popular",
    tip: {
      no: "The number cannot be empty",
      name: "The name cannot be empty",
      partsSn: "Accessory ID must be 34 characters",
    },
    result: {
      band: "Binding",
      noBand: "Unbound",
    },
  },
  partsType: {
    no: "Number",
    name: "Name",
    partsTypeSn: "Parts Type Serial Number",
    desc: "Description",
    tip: {
      no: "The number cannot be empty",
    },
  },
  uiSetting: {
    kanbanTitle:"KanBan Title",
    labelWidth: "110px",
    currentSetting: "Current settings",
    systemName: "Systematic name",
    logoLarge: "Logo large",
    logoSmall: "Logo Small",
    banner: "Home slideshow",
    appBanner: "App slideshow",
    companyName: "Company",
    faviconIcon: "Favicon icon",
    tip: {
      fileType: "Only jpg and png images can be uploaded!",
      isLt2M: "Image size must not exceed 2MB!",
      pictures: "Numero di immagini massimo superato, per favore elimina alcune immagini prima di aggiungerne altre",
    },
  },
  userThirdPart: {
    video: "Call",
    loginId: "Account number",
    userType: "User type",
    account: "HuanXin account",
    password: "PassWord",
    choose: "Choose account",
    tip: {
      account: "The HuanXin account cannot be empty",
      password: "The length is 6-20 digits and the letter combination",
    },
  },
  useUnit: {
    coordinate: "Coordinate",
    no: "No.",
    unitCode: "Credit Code",
    name: "Name",
    type: "Type",
    propertyComId: "Property",
    buildingCount: "Building",
    districtId: "District",
    address: "Address",
    lng: "Longitude",
    lat: "Latitude",
    person: "Person",
    contactTel: "ContactTel",
    phone: "Telephone",
    note: "Note",
    tip: {
      useUnitName: "Please enter use unit name",
      name: "The name cannot be empty",
      propertyComId: "The property com name cannot be empty",
      districtId: "The district cannot be empty",
      lng: "The longitude cannot be empty",
      lat: "The latitude cannot be empty",
    },
  },

  company: {
    installPhone: "Installation Company Phone",
    useUnit: "Use Unit",
    manufacturerCompany: "Manufacturer Company",
    propertyCompany: "Property Management Company",
    maintenanceCompany: "Maintenance Company",
    installationCompany: "Installation Company",
    modificationCompany: "Modification Company",
    add: "Add",
    tenantName: "Tenant",
    renovationCompany: "Renovation Company",
    addUser: "Add User",
    company: "Company",
    width: "200px",
    no: "No.",
    name: "Name",
    abbreviation: "Abbreviation",
    elevCount: "Elevator Count",
    userCount: "User Count",
    externalNo: "External No.",
    brand: "Brand",
    type: "Type",
    parentId: "Superior",
    person: "Person",
    phone: "Phone",
    factoryNo1: "Factory No.",
    dedicatedCode: "Dedicated Code",
    unifiedSocialCreditCode: "Unified social credit code",
    tip: {
      no: "The agency number cannot be empty",
      name: "The name cannot be empty",
    },
  },
  quantity: {
    no: "No.",
    name: "Name",
    abbreviation: "Abbreviation",
    districtId: "Region",
    address: "Address",
    tip: {
      no: "The organization number cannot be empty",
      name: "The organization name cannot be empty",
      districtId: "The administrative region cannot be empty",
      address: "The organization address cannot be empty",
    },
  },
  role: {
    role: "Role",
    labelWidth: "120px",
    auth: "Permission list",
    name: "Name",
    isDefault: "Default role",
    orgName: "Enterprise name",
    tip: {
      name: "The role name cannot be empty",
    },
  },
  device: {
    second: "Second",
    min:"Minute",
    exportExcel: "Export Excel",
    onlineRate: "Online Rate",
    labelWidth: "130px",
    sendA0: "Send A0",
    code: "Number",
    dtuNum: "DTU",
    nodeNum: "Node",
    onlineNode: "Online",
    status: "Status",
    online: "Online",
    offline: "Offline",
    port: "Port",
    video: "Video",
    softwareVersion: "Software Version",
    hardwareVersion: "Hardware Version",
    signalIntensity: "Signal Intensity",
    temperature: "Temperature",
    electricQuantity: "Electric Quantity",
    setUp: "Set Up",
    read: "Read",
    elevatorNum: "Elevator Count",
    APNName: "APN Name",
    userName: "UserName",
    password: "Password",
    all: "All",
    refresh: "Refresh",
    simCard: "SIM Card",
    record: "Record",
    upgrade: "upgrade",
    monitor: "Monitor",
    expandAll: "Expand All",
    collapseAll: "Collapse All",
    upgradeConfirm: "Confirm the upgrade?",
    iccidIsEmpty: "iccid Is Empty",
    offlineDuration: "Offline duration",
    within2Hours: "Within 2 hours",
    more2hoursLess1Day: "More than 2 hours and less than 1 day",
    moreThan1Days: "More than 1 days",
    custom: "custom",
    minute: "minute",
    hour: "hour",
    km:"KM",
    day: "day",
    illegalType: "Illegal type input",
    cannotBeGreater: "Start time cannot be greater than end time",
    pleaseEnter: "Please enter the query time",
    intercom: "Intercom Set",
    serverAddress: "Address",
    callNos: "Call Nos",
    dutyRoom: "Duty Room",
    volume: "Volume",
    inFilter: "In Filter",
    inFilterOpen: "Open",
    inFilterClose: "Close",
    account: "SIP Account",
    readLoadingTxt: "Parameter reading, please wait patiently",
    setLoadingTxt: "Parameter writing, please wait patiently",
    readSuccess: "Read parameter success",
    readFail: "Read parameter failure",
    writeSuccess: "Set parameter success",
    writeFail: "Set parameter failure",
  },
  mb: {
    changeType: "Operational Context",
    changeTime: "Operation Time",
    eventName: "Event Name",
  },
  oldMaintWorkOrder: {
    confirmed: "Confirmed",
    wait: "wait for confirmation",
    invalid: "Invalid",
  },
  msgSend: {
    phoneNo: "Sent phone number ",
    content: "Sent content",
    sendTime: "Send time",
  },
  userLoginLog: {
    userName: "User Name",
    name: "Name",
    startTime: "Start Time",
    endTime: "End Time",
    loginType: "Login Type",
    loginIp: "Login IP",
    loginTime: "Login Time",
    web: "Web",
    app: "APP",
    wx: "WX",
  },
  newMsgSend: {
    type: "Send Type",
    content: "Send Content",
    target: "Target customer",
    referBizNo: "Refer No",
    sendTime: "Send Time",
    msgPush: "APP Message Push",
    sms: "sms",
    email: "Email",
    wxMsgPush: "Weixin Message Push",
    status: "Send Status",
    failSend: "Send Failed",
    sendSucceed: "Send Succeed",
    notSend: "Not Send",
  },
  userOperateLog: {
    userName: "User Name",
    name: "Name",
    startTime: "Start Time",
    endTime: "End Time",
    operateContent: "Operate Content",
    operateLog: "Operate Log",
    appOperateLog: "App Operate Log",
    operateTime: "Operate Time",
  },
  msgTemplate: {
    name: "Name",
    content: "Message Content",
    supportVars: "AvailableParameters",
  },
  userDebugInfo: {
    userDebugInfo: "User Debug Info",
    userName: "User",
    debugLevel: "Debug Level",
    passwordExpirationDate: "Password Ex-Date",
    contractNOs: "Contract NO.",
    tip: {
      placeholder: "Please separate multiple contract NO in English comma.",
      userId: "Please select the user",
      debugLevel: "Please select the debug level",
      passwordExpirationDate: "Please select Password Ex-Date",
      contractNOs: "Please enter the contract NO",
    },
  },
  potentialFaultSetting: {
    potentialFaultSetting: "Potential Fault Setting",
    name: "Name",
    warningValue: "Warning Value",
    statPeriod: "Stat Period（day）",
    note: "Note",
    faultTemplate: "Fault Template",
    baseInfo: "Base Info",
    details: "Details",
    faultCode: "Fault Code",
    weight: "Weight",
    operate: "Execution statistics",
    tip: {
      name: "Please select the Name",
      warningValue: "Warning Value must be more than 0",
      statPeriod: "Stat Period must be more than 0",
      faultTemplate: "Please enter the Fault Template",
      faultCode: "number more than 0",
      weight: "number 0 - 100",
      operateSuccess: "Execution Success",
      operateError: "Execution Failed",
    },
  },
  sysItem: {
    name: "Type",
    value: "Value",
    status: "ON-OFF state",
    open: "ON",
    close: "OFF",
  },
  gatewayVersion: {
    gatewayVersion: "gateway Version",
    sofewareName: "Sofeware Name",
    sofewareVersionID: "Sofeware Version ID",
    sofewareVersion: "Sofeware Version",
    status: "Status",
    isFullAdapter: "Is FullAdapter",
    note: "Note",
    upload: "Upload",
    click: "Click",
    md5: "MD5",
    releaseDate: "Release Date",
    creator: "Creator",
    yes: "Yes",
    no: "No",
  },
  elevatorUser: {
    elevatorUser: "Elevator and User Binding",
    selectLift: "Select Lift",
    selectUser: "Select User",
    elevatorNotSelected: "Elevator Not Selected",
    userNotSelected: "User Not Selected",
  },
  thirdPartyPlatform: {
    name: "Name",
    note: "Note",
    set: "Set",
    companyName: "Company Name",
    account: "Account",
    password: "Password",
  },
  vibrationData: {
    speedMax:"SpeedMax",
    speedMin:"SpeedMin",
    scanCheck:"Please use the Elevator Cloud APP to scan the code to view",
    moreVib:"View more related curves",
    idNo: "ID No.",
    elevatorName: "Elevator Name",
    createTime: "Create Time",
    runState: "Run state",
    dataDesc: "Remark",
    downLoad: "DownLoad",
    curve: "Curve",
    tab1: "DownList",
    tab2: "Operator",
    collectTime: "Collect Time",
    collectFrequency: "Collect Frequency",
    collectType: "Collect Type",
    calibration: "Calibration",
    eocdParamsSet: "Params set",
    searchEOCDData: "Search EOCD Data",
    startCollect: "Start Collect",
    endCollect: "End Collect",
    clearCollect: "Clear Record",
    cancelCollect: "Cancel Collect",
    parTotalSwitch: "EOCD Switch",
    parCollectSwitch: "EOCD Collect Switch",
    parCollectCycle: "Collect Cycle",
    parCollectFrequency: "Collect Frequency",
    acquisitionParameters: "Acquisition Parameters",
    autoUpload: "Auto Upload",
    doorCollectSwitch: "Door Collect Switch",
    doorCollectCycle: "Door Collect Cycle",
    alarmFileSwitch: "Alarm File witch",
    safetyHazardSwitch: "Safety Hazard Switch",
    safetyHazardRecord: "Safety Hazard Record",
    read: "Read",
    set: "Set",
    dtuNoOnline:
      "The terminal is not online and does not support this operation",
  },
  alarm: {
    id: "alarm id",
    mid: "mid",
    code: "code",
    value: "value",
    description: "description",
    picUrl: "image",
    videoUrl: "video",
    uploadTime: "time",
    fileType: "File Type",
    filePath: "File Path",
    imageOrVideo: "Image / Video",
  },

  // 日周月管理
  dayWeekMonthManger: {
    filter: "Keyword Search",
    status: "Status",

    isAbnormal: "isAbnormal",
    abnormal: "Abnormal",
    notAbnormal: "Normal",
    number: "Number",
    elevatorNo: "Elevator No.",
    elevatorName: "Elevator Name",
    useUnit: "Use Unit",
    controlResult: "Control Result",
    controlDate: "Control Date",
    checkResult: "Check Result",
    dispatchResult: "Dispatch Result",
    dispatchCycle: "Dispatch Cycle",
    checkCycle: "Check Cycle",
    to: " To ",
    createTime: "Creation Time",
    creator: "Creator",
    operate: "Operate",
    edit: "Edit",
    delete: "Delete",
    view: "View",
    anomalies: " Anomalies",
    ok: "Ok",
    cancel: "Cancel",
    index: "Index",
    type: "Type",
    controlItems: "Daily Control Project",
    checkItems: "Weekly Check Project",
    dispatchItems: "Monthly Dispatch Project",
    handleResult: "Handle Result",
    templateNo: "Template No.",
    templateName: "Template Name",
    enterprise: "Affiliated Company",
    isDefault: "Default or Not",
    hideItems: "Hidden Project",
    showAll: "Show All",
    show: "Show",
    projectType: "Project Type",
    projectName: "Project Name",
    precaution: "Protection Measures",
    risk: "Verification Of The Rectification Of Safety Risks And Hidden Dangers Identified Last Week",
    rectification:
      "Major Safety Risks And Hidden Dangers This Week And Their Rectification Status",
    safetyManagementEvaluation: "Evaluation of This Week's Safety Management",
    workFocus: "Next Week's Work Focus",
    safetyOfficerOpinion: "Safety Officer's Opinion",
    safetyDirectorOpinion: "Safety Director's Opinion",
    mainProblem: "Main Issues of This Month's Inspection",
    rectification1: "本月整改方案落实情况",
    unresolvedProblem:
      "Implementation Status Of This Month's Rectification Plan",
    scheduleContent: "Contents Related To Monthly Scheduling",
    safetyContent: "Other Safety Matters",
    precaution1: "Research And Measures Adopted In The Meeting",
    tip: {
      confirmDelete:
        "Are you sure you want to delete the selected records? Deleted records cannot be recovered",
      deleteSuccess: "Delete Success",
      deleteError: "Delete failure",
      handleResult: "Please enter the result of handling",
    },
    count: "Number Of Activated Terminals",
  },

  // 事件管理
  event: {
    directElevator: "Elevator",
    escalator: "Escalator",
    newEvent: "New Event",
    elevatorDPC: "Battery Car Entering Elevator",
    dtuNumber: "DTU Number",
    faultSn:"Fault Record Number",
    faultRecoverTime: "Fault Recover Time",
    warning:"Tip: Please enter the terminal number, otherwise the query result will be empty.",
    filter: "Keyword Search",
    occurrenceTime: "Occurrence Time",
    export: "Export Data",
    elevatorNo: "Elevator No.",
    elevatorName: "Elevator Name",
    node: "Node",
    useUnit: "Use Unit",
    gatewayPowerDown: "Gateway Power Down",
    nonGateAreaStop: "Non-landing zone stop",
    alarmBell: "Alarm Bell",
    enterStopService: "Enter Stop Service",
    gatewayPowerFailure: "Gateway Power Failure",
    repair: "Service",
    event: "Event",
    data: "Data",
    alarmTime: "Alarm Time",
    picture: "Picture",
    alarmType: "Alarm Type",
    powerDownTime: "Power Down Time",
    registerCode: "Device code",
    whetherToRestore: "Recover State",
    fileName: "GatewayPowerDown.xlsx",
    callRecord: "Call Record",
    workOrderFileName: "WorkOrder Info.xlsx",
    alarmFileName: "AlarmRecord Info.xlsx",
    callTime: "Call Time",
    callDuration: "Call Duration",
    originator: "Call Recipient",
    connector: "Connect Recipient",
    phone: "Connect Number",
    reset: "Reset",
    audio: "Audio",
    playAudio: "Play Audio",
    faultType: "Fault Type",
    faultTime: "Fault Time",
    boardFaultTime: "Board Time",
    faultNo: "Fault Code",
    faultFloorCode: "Fault Floor Code",
    faultFloor: "Fault Floor",
    displayFaultFloor: "Displayed Floor",
    faultRecordFileName: "FaultRecord Info.xlsx",
    safetyHazardTime: "Safety Hazard Time",
    safetyHazardCode: "Safety Hazard Code",
    safetyHazardDesc: "Safety Hazard Desc",
    startFloor: "Start Floor",
    endFloor: "End Floor",
    runningDistance: "Running Distance",
    maxSpeed: "Max Speed",
    maxAcceleration: "Max Acceleration",
    maxVariableAcceleration: "Max Variable Acceleration",
    recordTime: "Record Time",
    callElevatorStatistics: "Call Statistics",
    username: "Username",
    registerMobile: "Registered mobile",
    implementationElevator: "Execute Elevator",
    userLocation: "User Location",
    currentFloor: "Current Floor",
    destinationFloor: "Destination Floor",
    operationType: "Operation Type",
    remark: "Remarks",
    externalCallUp: "External Call Up",
    externalCallDown: "External Call Down",
    carCall: "In-car Call",
    callElevatorTimes: "Call count",
    tip: {
      fillThreeDays: "Please fill in the occurrence time (within three days)",
      fillSevenDays: "Please fill in the power-off time (within seven days)",
      fillFifteenDays: "Please fill in the time within 15 days!",
      fillMonthDays: "Please fill in the time within one month！",
      alarmTime: "Please fill in the alarm time (within seven days)",
      faultTime: "Please fill in the fault time (within two months)",
    },
  },
  // 电梯监控
  monitor: {
    change: "Switching Maps",
    mute: "Mute",
    area: "Area",
    clearSearch: "Clear Search",
    escalatorMonitor: "escalator Monitor",
    escalatorConnect: "Connection...",
    error: "error",
    open: "on",
    close: "off",
    buildNum: "BNum",
    ladderNum: "LNum",
    runningSpeed: "running speed",
    retry: "retry",
    videoOnConnect: "Connecting, please wait...",
    videoConnectFailed: "Connection failed to see if plugins are installed...",
    a08Describe: "Monitoring data timeout",
    a10Describe: "Main board off-line",
    a11Describe: "Gateway off-line",
    a06Describe: "not found in the cache",
    a05Describe: "Number of queues",
    connectionDisconnection: "Disconnect",
    currentUseUnit: "Current UseUnit",
    elevatorMonitor: "Monitor",
    onlineStatus: "Online Status",
    online: "Online",
    offline: "Offline",
    village: "Village Name",
    monitoring: "Multiple Escalator Monitoring",
    monitor: "More Monitoring",
    monitorIBE: "ITE IOT",
    monitorA4Io: "IO",
    information: "Primary Information",
    liveData: "Live Data",
    transducer: "Transducer",
    controlPanel: "Control panel software version",
    unControlPanel: "Control board software is not standard edition",
    operationPanel: "Operation panel software version",
    customerNumber: "Customer customized series number",
    selection: "Motor and control mode selection",
    inverterStatus: "Inverter Status",
    setFrequency: "Set Frequency",
    OutputFrequency: "Output Frequency",
    OutputVoltage: "Output Voltage",
    OutputCurrent: "Output Current",
    DCBusVoltage: "DC Bus Voltage",
    PowerOnTimeAccumulation: "Power on time",
    RunningTime: "Running time",
    FaultCode: "Fault Code",
    workingTime: "Working Time",
    faultTime: "Fault Time",
    check: "Next Year Check",
    maintenance: "Next Maintenance Time",
    elevator: "The Elevator Profile",
    state: "Latch State",
    lock: "Lock",
    goUp: "Go Up",
    goDown: "Go Down",
    record: "Record",
    faultCode:"Fault Occurred-Code",
    speedPro:"Overspeed protection",
    nonPro:"Non-manipulation reversal protection",
    stepPro:"Step or pedal missing protection",
    driveBorken:"Drive chain broken or stretched",
    combBlock:"Comb plate is blocked by foreign matter",
    stepSagg:"Steps or pedals are sagging",
    emergStop:"Emergency stop switch action",
    status: {
      reConnection:"Reconnection",
      switchElevator:"SwitchElevator",
      callUp:"Calling Up",
      callDown:"Calling Down",
      carCall:"Car Calling",
      faultReset: "Fault Reset",
      open: "Open",
      close: "Close",
      motion: "Motion",
      parallelControl: "In Parallel/Cluster Control",
      fault: "Fault",
      service: "Service",
      overload: "Overload",
      networkControl: "Network Control",
      brakeSwitch: "Brake Switch",
      brakeContactorDetection: "Brake Contactor Detection",
      gateArea: "Gate Area",
      openDoorInPlace: "Open Door in Place",
      closeTheDoorInPlace: "Close the Door in Place",
      currentService: "Current Service Mode Unknown",
      isAnyone: "Is there Anyone in the Elevator Car",
      stopsOutside: "The Elevator Stops Outside the Unlocked Area",
      duringOperation: "Open the Door During Operation",
      rushTop: "Rush to the Top",
      squatBottom: "Squat on the Bottom",
      speeding: "Speeding",
      unexpectedMovement: "Unexpected Movement of Elevator Car",
      safetyCircuitBreak: "Safety Circuit Break",
      circuitMalfunction: "Floor Door Lock Circuit Malfunction",
      circuitFault: "Elevator Car Door Lock Circuit Fault",
      controlDevice: "Elevator Control Device Failure",
      machineFailure: "Traction Machine Failure",
      inverterFailure: "Inverter Failure",
      systemFailure: "Elevator Brake System Failure",
      shortCircuit: "Door Lock Electrical Device Short Circuit",
      levelingInductionFault: "Leveling Induction Fault",
      floorLocationLost: "Floor Location Lost",
      limiterAction: "Motor Operation Time Limiter Action",
      closeDoorFault: "Close the Door Fault",
      openDoorFault: "Open the Door Fault",
      emergencyStopFault: "Emergency Stop Fault",
      runningDistance: "Elevator running distance",
      stationLayer: "Number of door openings at the base station layer",
      numberOfDoorOpenings: "Number of door openings",
      touchpadActions: "Number of safety touchpad actions",
      curtainActions: "Number of light curtain actions",
      lighting: "Number of times of elevator car lighting",
      lightingTime: "Elevator car lighting time",
      downwardRelevelingTimes: "Downward releveling times",
      upwardRelevelingTimes: "Upward releveling times",
      runSpeed: "Run Speed",
      instructionSettings: "Instruction settings",
      IcCardAuthority: "IC card authority",
      remoteLockElevator: "Remote lock elevator",
      Open: "open",
      Close: "close",
      setting: "Setting",
      showFloor: "Show Floor",
      elevatorCarCommand: "Elevator car command",
      hallInstruction: "Outside the elevator hall instruction",
      cancel: "Cancel",
      saveSettings: "Save Settings",
      physicalFloor: "Physical Floor",
      allClosed: "All closed",
      inExecution: "In execution",
      currentSettings: "Read the current settings",
      fullyOpen: "Fully open",
      loaded: "Fully",
      safety: "Safety Circuit Break",
      fire: "Firemen",
      fireReturn: "Fire Return",
      earthquakeModel: "Earthquake Model",
      emergencyPower: "Emergency Power",
      serviceable: "Elevator Serviceable",
      mainPowerOff: "Main Power Off",
      openButton: "Open Button",
      independence: "Independence",
      Lock: "Lock",
      driver: "Driver",
      unKnow: "unKnow",
      passengerStatus: "The elevator was",
      doorCircuit: "Door Circuit Break",
    },
    parameter: "Operating Parameter",
    parameters: {
      times: "Elevator operation times",
      time: "Elevator operation time",
      wire: "The number of bending times of wire rope",
      openTimes: "Lift open and close times",
    },
    mark: {
      position: "Current Position",
    },
    fault: {
      fault1: "Normal Operation",
      fault2: "Fault",
      fault3: "Disconnected",
    },
    elevatorName: "Name",
    alarmType: "Type",
    continueTime: "Continue Time",
    operation: "Operation",
    elevatorStop: "Elevator Stop",
    elevatorStranded: "Elevator Stranded",
    alarmBellsAlarm: "Alarm Bells Alarm",
    tip: {
      confirmReset: "Confirm Reset?",
      faultResetFail: "Fault Reset Failed",
      faultResetSuccess: "Fault Reset Successful",
    }
  },
  yearCheck: {
    // labelWidth: "150px",
    yearCheckNo: "Annual Inspection No.",
    notice: "YearCheck Notice",
    expired: "Expired Without YearCheck",
    checkDate: "Inspection Date",
    date: "Option date",
    rectificationDate: "Estimated Rectification Date",
    all: "All",
    status: "Result",
    note:"Note",
    file:"Annual inspection documents",
    // note: "Note",
    result: {
      pass: "Pass",
      noPass: "No pass",
    },
    tip: "Please select elevator",
  },
  maintenanceArea: {
    machineRoom: "Machine Room",
    bridge: "Lift Car",
    layer: "Floor",
    wellRoadAndTheBottomPit: "Shaft and Pit",
    escalator: "Escalator",
    other: "Other",
  },
  // 维保管理
  maintWorkOrder: {
    completed: "Completed",
    filter: "Keyword Search",
    maintType: "Maintenance Type",
    maintItem: "Maintenance Items",
    maintSetting: "Maintenance Setting",
    branchAgency: "Branch Agency",
    safetySpotCheck: "Safety Spot Check",
    productTypeName: "Elevator Type",
    maintTypeName: "Maintenance Type Name",
    elevatorType1: "Traction Drive Passenger Elevator",
    elevatorType2: "Traction Drive Freight Elevator",
    elevatorType3: "Forced Drive Freight Elevator",
    elevatorType4: "Hydraulic Passenger Elevator",
    elevatorType5: "Hydraulic Freight Elevator",
    elevatorType6: "Escalator",
    elevatorType7: "Moving Walkway",
    elevatorType8: "Explosion-proof Elevator",
    elevatorType9: "Firefighter Elevator",
    elevatorType10: "Sundries Elevator",
    selectMaintItem: "Select Maintenance Type Items",
    maintenanceProjectName: "Maintenance Project Name",
    maintContent: "Maintenance Content",
    maintRequire: "Maintenance Requirements",
    maintArea: "Maintenance Area",
    branchName: "Branch Agency Name",
    salesOrderNo: "Order Number",
    phone: "Phone",
    contacts: "Contacts",
    featureDetails: "Feature Details",
    featureNumber: "Feature Number",
    featureName: "Feature Name",
    featureValue: "Feature Value",
    completionStatus: "Completion Status",
    rectificationStatus: "Rectification Status",
    spotCheckResults: "Spot Check Results",
    spotCheckNumber: "Spot Check Number",
    cegion: "Cegion",
    branch: "Branch",
    projectName: "Project Name",
    maintenanceCompany: "Maintenance Company",
    elevatorName: "Elevator Name",
    deviceNumber: "Device Number",
    maintenanceBySelf: "Maintenance by Self",
    subcontract: "Subcontract",
    spotCheckInspector: "Spot Check Inspector",
    maintenanceWorker: "Maintenance Worker",
    spotCheckDate: "Spot Check Date",
    spotCheckInformation: "Spot Check Information",
    unrectified: "Unrectified",
    rectified: "Rectified",
    elevator: "Elevator",
    specification: "Specification",
    totalScore: "Total Score",
    maintenanceQuality: "Maintenance Quality",
    basicConditions: "Basic Conditions",
    safety: "Safety",
    checkContent: "Inspection Content",
    confirmProject: "Confirm Project",
    category: "Category",
    completionTime: "Completion Time",
    isQualified: "Qualified or Not",
    signToConfirm: "Sign to Confirm",
    spotCheckInspectorConfrim: "Spot Check Inspector Confrim",
    maintenanceWorkerConfirm: "Maintenance Worker Confrim",
    type: {
      halfMonth: "Semi-monthly Maintenance",
      month: "Quarterly Maintenance",
      halfYear: "Half-yearly Maintenance",
      year: "Annual Maintenance",
    },
    maintenanceArea: {
      machineRoom: "Machine Room",
      bridge: "Lift Car",
      layer: "Floor",
      wellRoadAndTheBottomPit: "Shaft and Pit",
      escalator: "Escalator",
      other: "Other",
    },
    setting: {
      autoSendDay: "Auto Send Day: ",
      maintDistance: "Maintenance Distance",
      day: "days",
      meter: "meters",
      isAutoGenerate: "Auto Generate: ",
      generateInitialTime: "Generate Initial Time: ",
      completeDate: "Complete Date",
      planDate: "Plan Date",
      maintCycleDay: "Maintenance Cycle: ",
      signInMethod: "Sign In Method: ",
      photo: "Take Photo",
      isSignInApp: "Are customers allowed to sign on the maintainer's app? : ",
      isOperationOverdue:
        "After the maintenance work sheet is extended, can you continue the operation? : ",
      isTwoMaintainerCheckIn:
        "Are both of the maintainers allowed to sign up? : ",
      isPaperMaintPhotoRequired:
        "Must upload photos of the papery maintenance order? : ",
      fieldWorkMethods: "Please select the following field work methods: ",
      default:
        "Default: field operations other than the sign in and finish operations, there are no restrictions on the operation, and other operations must be completed",
      custom: "Custom",
      isPhotoCheckIn: "Must sign in by taking pictures",
      isMaintainerMustSignInApp:
        "The maintenance personnel must sign on the App before completing the work order",
      isPhotoAbnormal: "For abnormal maintenance items, you must take pictures",
      isSoundRecordAbnormal:
        "For abnormal maintenance items, recordings must be made",
      isContextAbnormal:
        "For abnormal maintenance items, you must fill in the text description",
      isPhotoNormal: "For normal maintenance projects, you must take pictures",
      isSoundRecordNormal:
        "For normal maintenance projects, recordings must be made",
      isContextNormal:
        "For normal maintenance items, you must fill in the text description",
    },
    tip: {
      getMaintTypeItemError: "Get maintenance type items failed",
      maintTypeName: "Maintenance type name cannot be empty",
      elevatorProductType: "Elevator type cannot be empty",
      maintTypeClassify: "Maintenance type cannot be empty",
      itemName: "Maintenance item name cannot be empty",
      maintContent: "Maintenance content cannot be empty",
      maintRequire: "Maintenance requirements cannot be empty",
      maintArea: "Maintenance area cannot be empty",
      getElevatorProductTypeError: "Get Elevator Type Failed",
      sendTip:
        "enter a value between 0 and 7, and the 0 value is not sent automatically",
      cycleTip: "enter a value between 0 and 90",
      distanceTip: "enter a value greater than 0",
      score: "Please rate each item",
      elevator: "Please select elevator",
      timeError: "Plan start date must be greater than end date",
      timeError1: "The current date interval exceeds the maintenance cycle",
      planStartDate: "Plan start date cannot be empty",
      planEndDate: "Plan end date cannot be empty",
      maintCycle: "Please input a number greater than 0",
      emptyPlan: "Maintenance plan cannot be empty",
      elevatorNo: "Elevator number cannot be empty",
      maintType: "Maintenance type cannot be empty",
      date: "Maintenance date cannot be empty",
      invalidReason: "Invalid reason cannot be empty",
      emptyMaintType: "Please select maintenance type",
      confirmDelete:
        "Are you sure you want to delete the selected records? Deleted records cannot be recovered",
      emptyMultipleRow: "Please select at least one record",
      emptySingleRow: "Please select one record",
      confirmInvalid:
        "Are you sure to invalid the selected elevator maintenance plan? The invalided maintenance plan cannot be restored",
      invalidSuccess: "Invalid Success",
      invalidError: "Invalid Error",
      confirmSend: "Confirm to send the selected order?",
      sendSuccess: "Send Success",
      sendError: "Send Error",
    },
    condition: {
      notProcessed: "Not Processed",
      noSuchItem: "No Such Item",
      qualified: "Qualified",
      qualifiedAfterAdjustment: "Qualified After Adjustment",
      unqualified: "Unqualified",
    },

    maintDevice: "Maintenance Device",
    maintWorkOrder: "Maintenance Order",
    all: "All",
    send: "Send",
    completionSituation: "Completion Situation",
    maintPlan: "Maintenance Plan",
    clearSelection: "Clear Selection",
    maintStatus: "Maintenance Status",
    planDate: "Maintenance Plan Date",
    propertyCom: "Property Company",
    maintDate: "Maintenance Date",
    maintDuration: "Maintenance Duration",
    normal: "Normal",
    advance: "Advance",
    overdue: "Overdue",
    invalid: "Invalid",
    inPlan: "In Plan",
    notAccepted: "Not Accepted",
    accepted: "Accepted",
    signedIn: "Signed In",
    creator: "Creator",
    plan: {
      elevator: "Elevator",
      selected: "Plans can be added. ",
      row: " selected",
      have: "Yes",
      no: "Nothing",
      planStartTime: "Plan Start Time",
      planEndTime: "Plan End Time",
      maintCycle: "Maintenance Cycle",
    },
    haveMaintPlan: "Have Maintenance Plan",
    orgName: "Company Name",

    recentNotAccept: "Last 24 Hours Not Accept",
    expired: "Expired Without Maintenance",
    selectElevator: "Select Elevator",
    checkMaintItem: "Check Maintenance Item",
    remark: "Remark",
    invalidReason: "Invalid Reason",

    workCondition: "Work Condition",

    situationDescription: "Situation Description",
    picture: "Picture",
    soundRecord: "Sound Record",
    processTime: "Process Time",

    generatePlan: "Generate Plan",
    currentLocation: "Current Location",
    orderNum: "Order No.",
    created: "Created",
    notSend: "Not Send",
    notSignIn: "Not Sign In",
    maintBaseInfo: "Maintenance Base Info",
    maintainerName1: "Maintenance Worker1",
    maintainerName2: "Maintenance Worker2",

    treatmentProcess: "Treatment Process",
    maintTypeClassify: "Maintenance Type Classify",
    isDefault: "Is Default",
    itemName: "Item Name",

    sigAndReturnVisit: "Customer Signature And Return Visit",
    evaluation: "Maintenance Quality Service Evaluation",
    dissatisfied: "Dissatisfied",
    commonly: "Commonly",
    satisfied: "Satisfied",
    verySatisfied: "Very Satisfied",
    customerSignature: "Customer Signature",
    returnRecord: "Return Record",
    uploadPicture: "Upload live photos",

    allState: "All State",
    onlyPlan: "Only Plan",
    batchDeleteMaint: "Delete Maintenance Plans In Batches",
    maintenancePolicyStatus: "Maintenance Policy Status",
    title:
      "Each elevator only needs to add a maintenance plan, and the subsequent maintenance plan will be automatically generated after the last maintenance is completed or overdue, without adding again.  \n" +
      "If the elevator has an unfinished maintenance work order/no maintenance company/no maintenance worker, the elevator will be ignored when it is created. ",
    tips: "This function will delete all maintenance work orders of the selected elevator at the same time selected state, please use with caution.  Maintenance work orders will not be generated automatically after being deleted, which is equivalent to suspending maintenance",
    batchDelete: "Batch Delete",
    actualMaintenanceTime: "Actual maintenance time",
    export: "Export",
    no: "No.",
    company: "Maintenance company",
    date: "Maintenance date",
    status: "Status",
    State: "Status Changes",
    ultraTime: "Ultra time",
    hasExtended: "Has extended",
    works: "In the works",
    execution: "In execution",
    stocks: "Off the stocks",
    confirmed: "Confirmed",
    affirm: "Confirm",
    content: "Maintenance content",
    userEvaluation: "User evaluation",
    property: "Property signature",
    maintenance: "Maintenance work signature",
    satisfaction: "Satisfaction",
    advice: "Advice",
    dateList: {
      plan: "Date of the maintenance plan",
      start: "Start date of maintenance",
      completion: "Date of completion of maintenance",
      confirmation: "Date of confirmation of maintenance",
    },
    maintenanceWay: {
      halfMonths: "And a half months to protect",
      quarter: "Quarter to protect",
      halfYear: "Half a year to protect",
      year: "Year warranty",
    },
    maintenanceStatus: {
      free: "Maintenance-free",
      have: "Have to maintain",
      need: "Need to replace",
    },
  },
  // 公共
  common: {
    new:"New",
    newLevel:"New Level",
    selectService:"Please select a service staff",
    selectTenant:"Select Tenant",
    keyWord:"Enter keywords to filter",
    selectType:"Select Type",
    AccountStat:"Account Status",
    Enable:"Enable",
    Disable:"Disable",
    AnnualInspection: "Annual Inspection",
    laddering: "Industrial elevator",
    add: "Add",
    filter: "Keyword Search",
    remark: "Notes",
    isDefault: "Default or Not",
    search: "Search",
    reset: "Reset",
    operate: "Operate",
    operation: "Operate",
    edit: "Edit",
    delete: "Delete",
    yes: "Yes",
    no: "No",
    index: "No.",
    remove: "Remove",
    cancel: "Cancel",
    save: "Save",
    createTime: "Creation Time",
    creator: "Creator",
    isDelete: "Do you want to delete",
    tips: "Tips",
    clear: "Clear Selection",
    confirmSelect: "Confirm Selection",
    status: "Status",
    submit: "Submitted",
    notSubmit: "Unsubmitted",
    export: "Export Data",
    startDate: "Start Date",
    to: "To",
    endDate: "End Date",
    completed: "Completed",
    notCompleted: "Not Completed",
    view: "View",
    confirm: "OK",
    pleaseEnter: "Please Enter",
    pleaseSelect: "Please Select",
    draft: "Draft",
    result: "Result",
    generateElevatorRecords: "Generate Elevator Records",
    tip: {
      deleteSuccess: "Delete Success",
      deleteError: "Delete failure",
      baiduMapError: "Baidu Map Loading Failed",
      googleMapError:
        "Possible Due to Network Issues, Google Map Cannot Be Loaded",
      getDataError: "Failed to obtain data",
      notNull: " Cannot be none",
      needNum: "Please enter a number greater than 0",
      needNum1: "Please enter a number >= 0",
      saveSuccess: "Save Successful",
      completeFloorInformation: "Please complete the floor information",
      optional:
        "Factory number and elevator register code to fill in at least one!",
      saveError: "Save Failed",
      settingError: "Setting Error",
      settingSuccess: "Setting Success",
      addSuccess: "Add Success",
      addError: "Add failure",
      submitError: "Please modify and submit",
      operationSuccess: "Operation Success",
      operationFailure: "Operation failure",

      comSuccess:"Command sent successfully",
      lockSuccess:"Sending the ladder lock command succeeded",
      setupFailed:"Setup command failed",
      setupSucceed:"Setup command successful",
      getDataFailed:"Failed to get data",
      sentSuccess:"Sent read command successfully",
      getSetFailed:"Failed to get current settings!",
      saveSetSuccess:"Saving the current settings was successful!",
      saveSetFailed:"Failed to save current settings!",
      sentSaveSuccess:"Sent save command successfully",
      floorNotConfig:"Current floor not configured",
      floorHigher:"Current floor is higher than 64",
      startCon:"Starting Connection",
      disCon:"Disconnection",
      exceptionCon:"Connection Exception",
      startAu:"Starting Authentication",
      norCon:"Connection is normal",


    },
    generation: "Generation",
    seconds: " Seconds",
    hide: "Hide",
    moveUp: "Move Up",
    moveDown: "Move Down",
    searchFilter: "Keyword Search",
    minutes: "Minutes",
    fillThreeDays:
      "Please fill in the occurrence time first (within three days).",
    withinThreeDays: "Please fill in the time within three days!",
    powerTime: "Please fill in the power off time first (within seven days).",
    alarmTime: "Please fill in the alarm time first (within seven days).",
    faultTime: "Please fill in the failure time first (within seven days).",
    sevenTime: "Please fill in the time within seven days",
    keywordSearch: "Keyword Search",
    loading: "Loading",
    loadingFailed: "Loading failed, click Refresh",
    noData: "No data, click Refresh",
    hint: "Hint",
    failedServer: "Failed to connect to server",
    requestError: "Request server error",
    advanceSearch: "Advance Search",
    chooseAnArea: "Choose an Area",
    select: "Select",
    import: "Import",
    importType: "Please upload .XLSX file",
    importSuccess: "Import Success",
    importFailure: "Import Failure",
    generate: "Generate",
    detail: "Detail",
    setting: "Setting",
    setBlack: "Are you sure to blacklist the selected gateway device ?",
    cancelBlack:
      "Confirm the selected device gateway. Is the blacklist removed?",
    isGenerate: "Do you want to generate",
    isAdd: "Do you want to add",
    beginTime: "Begin Time",
    endTime: "End Time",

    confirmTip: "Do you want to delete this line",

    more: "More",
    less: "Less",
    set: "Set",
    more1: "More",
    efficient: "efficient",
  },

  //统计报告
  statsReport: {
    report: "Statistical reports",
    positions: "Current position > Statistical reports",
    cycle: "Statistical cycle",
    pdf: "Print pdf",
    info: "Elevator Info",
    name: "Elevator Name",
    no: "Elevator No",
    type: "Elevator Type",
    code: "Device code",
    number: "Factory Number",
    unit: "Use Unit",
    companyInfo: "Company Info",
    madeCompany: "Manufacturing Company",
    maintCompany: "Maintenance Company",
    worker1Name: "Maintenance worker1 Name",
    worker1Phone: "Maintenance worker1 PhoneNumber",
    worker2Name: "Maintenance worker2 Name",
    worker2Phone: "Maintenance worker2 PhoneNumber",
    rate1: "Failure to stop the ladder rate:",
    rate2: "Failure Trapped Rate:",
    time1: "Maintenance downtime:",
    time2: "Faulty stopping time of the elevator:",
    time3: "Rescue response time:",
    time4: "Rescue time:",
    percent: "Percentage",
    faultPercnet: "percentage of faults",
    month: "the current month",
    sixMonth: "Last six months",
    year: "This year up to now",
  },
};
